import {
  Box,
  IconButton,
  styled,
  Tooltip,
  tooltipClasses,
  Typography,
} from "@mui/material";
import { FC, useContext, useMemo } from "react";
import AreaChart from "../chart/AreaChart/AreaChart";

import PageTitle from "../PageTitle/PageTitle";
import { formatDate } from "../../helpers/DateHelpers";
import { RentIncreasesContext } from "../../context/RentIncreasesContext";
import { formatNumber, humanizeNumber } from "../../helpers/NumberHelpers";
import { GridColDef } from "@mui/x-data-grid";
import { RelocationsContext } from "../../context/RelocationsContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import DataGrid from "../DataGrid/DataGrid";

const RentIncreaseDetails: FC = () => {
  const rentIncreasesData = useContext(RentIncreasesContext);
  const relocationsData = useContext(RelocationsContext);

  const graphData = useMemo(() => {
    const entries = Object.entries(rentIncreasesData.monthly).map(
      ([key, value]) => {
        const keyComponents = key.split("M");

        return {
          rentChangeValue:
            value.cumulative_rent_change_2023 +
            value.cumulative_rent_change_2024,
          successRateValue: parseFloat(
            (
              100 -
              (value.relocations_without_increase / value.relocations_count) *
                100
            ).toFixed(2)
          ),
          label: formatDate(
            new Date(
              parseInt(keyComponents[0].padStart(4, "20")),
              parseInt(keyComponents[1]) - 1,
              1
            ),
            { day: false }
          ),
        };
      }
    );

    const rentIncreaseDomain = [
      0,
      Math.max(...entries.map((entry) => entry.rentChangeValue)) + 100,
    ];

    const successRateDomain = [
      Math.ceil(
        Math.min(...entries.map((entry) => entry.successRateValue)) / 10
      ) * 10,
      100,
    ];

    return {
      rentIncreaseGraph: {
        data: entries.map((item) => ({
          value: item.rentChangeValue,
          label: item.label,
        })),
        domain: rentIncreaseDomain,
      },

      successRateGraph: {
        data: entries.map((item) => ({
          value: item.successRateValue,
          label: item.label,
        })),
        domain: successRateDomain,
      },
    };
  }, [rentIncreasesData]);

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Unit ID",
      flex: 2,
    },
    {
      field: "address",
      headerName: "Address",
      flex: 2,
      renderCell: (params) => (
        <Tooltip
          title={
            <TooltipContent>
              <TooltipText>{params.value}</TooltipText>
            </TooltipContent>
          }
          enterDelay={500}
          componentsProps={{
            popper: {
              sx: {
                [`& .${tooltipClasses.tooltip}`]: {
                  backgroundColor: "transparent",
                },
              },
            },
          }}
        >
          {params.value}
        </Tooltip>
      ),
    },
    {
      field: "move_out_date",
      headerName: "Move Out Date",
      flex: 2,
    },
    {
      field: "old_rent_net",
      headerName: "Old Net Rent",
      flex: 1.5,
    },
    {
      field: "new_rent_net",
      headerName: "New Net Rent",
      flex: 1.5,
    },
    {
      field: "notes",
      headerName: "Notes",
      flex: 3,
      renderCell: (params) => (
        <Tooltip
          title={
            <TooltipContent>
              <TooltipText>{params.value}</TooltipText>
            </TooltipContent>
          }
          enterDelay={500}
          componentsProps={{
            popper: {
              sx: {
                [`& .${tooltipClasses.tooltip}`]: {
                  backgroundColor: "transparent",
                },
              },
            },
          }}
        >
          {params.value}
        </Tooltip>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      flex: 3,
      renderCell: (params) => (
        <Tooltip
          title={
            <TooltipContent>
              <TooltipText>{params.value}</TooltipText>
            </TooltipContent>
          }
          enterDelay={500}
          componentsProps={{
            popper: {
              sx: {
                [`& .${tooltipClasses.tooltip}`]: {
                  backgroundColor: "transparent",
                },
              },
            },
          }}
        >
          {params.value}
        </Tooltip>
      ),
    },
    {
      field: "url",
      headerName: "Trello",
      flex: 1,
      renderCell: (params) => (
        <Tooltip
          title={
            <TooltipContent>
              <TooltipText>View in Trello</TooltipText>
            </TooltipContent>
          }
          enterDelay={500}
          componentsProps={{
            popper: {
              sx: {
                [`& .${tooltipClasses.tooltip}`]: {
                  backgroundColor: "transparent",
                },
              },
            },
          }}
        >
          <IconButton onClick={() => window.open(params.value, "__blank")}>
            <FontAwesomeIcon icon={faArrowUpRightFromSquare} fontSize={16} />
          </IconButton>
        </Tooltip>
      ),
    },
  ];

  const rows = Object.entries(relocationsData).map(([key, value]) => ({
    id: key,
    ...value,
    old_rent_net: `${humanizeNumber(value.old_rent_net)} CHF`,
    new_rent_net: `${humanizeNumber(value.new_rent_net)} CHF`,
    move_out_date: formatDate(value.move_out_date.toDate()),
  }));

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <PageTitle title="Rent Increases on Relocation" />
      <Box display="flex" flexDirection="column" gap={2}>
        <Typography variant="subtitle2" fontWeight="bold">
          Rent Increases Completed (%)
        </Typography>
        <AreaChart
          values={graphData.successRateGraph.data}
          domain={graphData.successRateGraph.domain as [number, number]}
          width="100%"
          height="30vh"
          tooltipTitle="Rent Increases Completed"
          unit="%"
          showAxisUnit={true}
          props={{
            syncId: "x",
          }}
        />
        <Typography variant="subtitle2" fontWeight="bold">
          Cumulative Rent Increase (CHF)
        </Typography>
        <AreaChart
          values={graphData.rentIncreaseGraph.data}
          domain={graphData.rentIncreaseGraph.domain as [number, number]}
          width="100%"
          height="30vh"
          tooltipTitle="Rent Increased"
          unit="CHF"
          props={{
            syncId: "x",
          }}
          tooltipValueFormatter={(value) =>
            formatNumber({ value, options: { round: false, format: ", " } })
          }
        />
      </Box>
      <Typography variant="subtitle2" fontWeight="bold">
        Upcoming Relocations
      </Typography>
      <DataGrid
        height="400px"
        columns={columns}
        rows={rows}
        disableColumnMenu={true}
        disableRowSelectionOnClick={true}
        disableDensitySelector={true}
        hideFooter={true}
      />
    </Box>
  );
};

const TooltipContent = styled("div")`
  background-color: #121926;
  padding: 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  border-radius: 12px;
  gap: 4px;
`;

const TooltipText = styled("span")`
  align-self: stretch;
  color: #e3e8ef;

  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
`;

export default RentIncreaseDetails;
