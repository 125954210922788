import { TooltipProps } from "recharts";
import { useTheme } from "@mui/material/styles";
import { Box, styled, Typography } from "@mui/material";

const monthLabels = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];

const ComboChartTooltip = (
  props: TooltipProps<number, string> & {
    labels?: {
      [key: string]: string;
    };
    valueFormatters?: {
      [key: string]: (value: number) => string;
    };
    unit: string;
    seriesColors?: {
      [key: string]: "primary" | "secondary" | "error";
    };
    seriesIcons?: {
      [key: string]: string;
    };
  }
) => {
  const {
    active,
    payload,
    label,
    labels,
    unit,
    valueFormatters,
    seriesColors,
    seriesIcons,
  } = props;
  const theme = useTheme();

  if (active && payload && payload.length) {
    return (
      <CustomTooltipRoot
        display="flex"
        flexDirection="column"
        width="100%"
        height="100%"
      >
        <Typography variant="subtitle1" align="center">
          {label}
        </Typography>
        <Box display="flex" flexDirection="column" gap={0.5}>
          {payload
            .filter((entry) => {
              if (entry.value === 0) {
                return false;
              }
              if (unit === "%") {
                return !entry.name?.includes("Total");
              }
              return true;
            })
            .map((entry, index) => (
              <Box display="flex" alignItems="center" gap={1} key={index}>
                {seriesIcons?.[entry.name ?? ""] === "line" ? (
                  <svg width="14" height="14" viewBox="0 0 32 32">
                    <title></title>
                    <desc></desc>
                    <path
                      strokeWidth="4"
                      fill="none"
                      stroke={
                        theme.palette[
                          seriesColors?.[entry.name ?? ""] ?? "primary"
                        ].main ?? theme.palette.primary.main
                      }
                      d="M0,16h10.666666666666666
            A5.333333333333333,5.333333333333333,0,1,1,21.333333333333332,16
            H32M21.333333333333332,16
            A5.333333333333333,5.333333333333333,0,1,1,10.666666666666666,16"
                    ></path>
                  </svg>
                ) : (
                  <Box
                    width="10px"
                    height="10px"
                    sx={{
                      borderRadius: "50%",
                      background:
                        entry.name === "thisYearSpent" &&
                        monthLabels.indexOf(entry.payload.label) >=
                          new Date().getMonth()
                          ? `repeating-linear-gradient(-45deg, ${theme.palette.primary.light}, ${theme.palette.primary.light} 1px, transparent 2px, transparent 3px)`
                          : theme.palette[
                              seriesColors?.[entry.name ?? ""] ?? "primary"
                            ].main ?? theme.palette.primary.main,
                    }}
                  ></Box>
                )}
                <Typography variant="body2">
                  {entry.name === "thisYearSpent" &&
                  monthLabels.indexOf(entry.payload.label) >=
                    new Date().getMonth()
                    ? `${new Date().getFullYear()} projected`
                    : labels?.[entry.name ?? ""]}
                </Typography>
                <Typography variant="body2">
                  {valueFormatters?.[entry.name ?? ""]?.(entry.value ?? 0) ??
                    entry.value}
                  {unit === "%" ? "" : " "}
                  {unit}
                </Typography>
              </Box>
            ))}
        </Box>
      </CustomTooltipRoot>
    );
  } else {
    return null;
  }
};

const CustomTooltipRoot = styled(Box)`
  border-radius: 12px;
  background: ${(props) => props.theme.cardBackground};
  backdrop-filter: blur(10px);
  padding: 8px;
`;

export default ComboChartTooltip;
