import React, { useEffect, useState, createContext } from "react";
import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import { db } from "../firebaseConfig";
import Loading from "../components/Loading/Loading";

export interface WorkOrderType {
  counts: number;
  normalized_counts: number;
  workorder_service_type_name: string;
}

interface ContractorAssigned {
  contractor_full_name: string;
  counts: number;
  normalized_counts: number;
}

interface TechnicalHealthEntry {
  contractors_assigned: ContractorAssigned[];
  technical_health: number;
  wo_count: number;
  wo_types: WorkOrderType[];
}

type TechnicalHealthData = {
  daily: {
    [day: string]: TechnicalHealthEntry;
  };
  units: number;
  unitsWithIssues: number;
};

const initialValue: TechnicalHealthData = {
  daily: {},
  units: 0,
  unitsWithIssues: 0,
};

export const TechnicalHealthContext =
  createContext<TechnicalHealthData>(initialValue);

type TechnicalHealthProviderProps = {
  children: React.ReactNode;
};

const TechnicalHealthProvider: React.FC<TechnicalHealthProviderProps> = ({
  children,
}) => {
  const [data, setData] = useState<TechnicalHealthData>(initialValue);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const docRef = doc(db, "metrics", "3m_technical_health");
      const docSnap = await getDoc(docRef);

      if (!docSnap.exists()) {
        return;
      }

      const units = docSnap.data().total_count_units;
      const unitsWithIssues = docSnap.data().count_units_with_technical_issues;

      const collectionRef = collection(db, "investis-technical-health");
      const collectionSnap = await getDocs(collectionRef);

      if (!collectionSnap.empty) {
        const docs = collectionSnap.docs.map((doc) => [doc.id, doc.data()]);

        const daily = Object.fromEntries(docs);

        setData({
          daily,
          units,
          unitsWithIssues,
        });
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);
  return (
    <TechnicalHealthContext.Provider value={data}>
      {isLoading ? <Loading /> : children}
    </TechnicalHealthContext.Provider>
  );
};

export default TechnicalHealthProvider;
