import { collection, getDocs } from "firebase/firestore";
import { createContext, FC, ReactNode, useEffect, useState } from "react";
import { db } from "../firebaseConfig";
import Loading from "../components/Loading/Loading";

export type Building = {
  address: string;
  canton: string;
  lat: number;
  lng: number;
};

type BuildingsData = {
  [id: string]: Building;
};

export const BuildingsContext = createContext<BuildingsData>({});

type BuildingsProviderProps = {
  children: ReactNode;
};

const BuildingsProvider: FC<BuildingsProviderProps> = ({ children }) => {
  const [data, setData] = useState<BuildingsData>({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const collectionRef = collection(db, "buildings_geocoded");
      const collectionSnap = await getDocs(collectionRef);

      if (collectionSnap.empty) {
        return;
      }

      const buildingsData = Object.fromEntries(
        collectionSnap.docs.map((doc) => [doc.id, doc.data() as Building])
      );

      setData(buildingsData);
      setIsLoading(false);
    };

    fetchData();
  }, []);

  return (
    <BuildingsContext.Provider value={data}>
      {isLoading ? <Loading /> : children}
    </BuildingsContext.Provider>
  );
};

export default BuildingsProvider;
