import React, { useContext, useMemo } from "react";
import MetricCardSimple from "../MetricCardSimple/MetricCardSimple";
import PageTitle from "../PageTitle/PageTitle";
import {
  Bar,
  BarChart,
  Cell,
  LabelList,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Sector,
  SectorProps,
} from "recharts";
import { OpenLegalCasesContext } from "../../context/OpenLegalCasesContext";
import { CaseStatusContext } from "../../context/CaseStatus";
import SimpleChartTooltip from "../chart/SimpleChartTooltip";
import { Box, Typography } from "@mui/material";
import ChartContainer from "../chart/ChartContainer";
import { useTheme } from "@mui/material/styles";

const LegalCases: React.FC = () => {
  const theme = useTheme();
  const openLegalCasesData = useContext(OpenLegalCasesContext);
  const casesStatusData = useContext(CaseStatusContext);

  const barChartData = useMemo(
    () =>
      casesStatusData.data[0] ? Object.values(casesStatusData.data[0]) : [],
    [casesStatusData.data]
  );

  const pieChartData = useMemo(() => {
    const caseTypes = openLegalCasesData.data.openCases.case_types;
    if (Object.values(caseTypes).length === 0) return [];
    return Object.entries(caseTypes).map(([key, value], index) => ({
      label: key,
      value: value,
      tooltipLabel: key,
      color: theme.chartPalette[index],
    }));
  }, [openLegalCasesData.data.openCases.case_types, theme.chartPalette]);

  return (
    <Box display="flex" flexDirection="column" gap={3}>
      <PageTitle title="Legal Cases" />
      <Box display="grid" gridAutoColumns="1fr" gridAutoFlow="column" gap={3}>
        <MetricCardSimple
          title="Currently Open"
          value={openLegalCasesData.data.openCases.open_cases}
          caption=""
          unit="cases"
          variant="simple"
          disableHover
        />
        <MetricCardSimple
          title="Billable This Month"
          value={openLegalCasesData.data.openCases.billable_legal_cases}
          caption=""
          unit="cases"
          variant="simple"
          disableHover
        />
        <MetricCardSimple
          title="Closed"
          value={
            openLegalCasesData.data.openCases.all_cases -
            openLegalCasesData.data.openCases.open_cases
          }
          caption=""
          unit="cases"
          variant="simple"
          disableHover
        />
        <MetricCardSimple
          title="Total"
          value={openLegalCasesData.data.openCases.all_cases}
          caption=""
          unit="cases"
          variant="simple"
          disableHover
        />
      </Box>
      <Box display="flex" gap={3}>
        <ChartContainer
          width="50%"
          height="60vh"
          display="flex"
          flexDirection="column"
          gap={2}
        >
          <Typography variant="subtitle2">
            Total Number of Legal Cases per Status
          </Typography>
          <ResponsiveContainer width="100%" height="90%">
            <BarChart layout="vertical" data={barChartData}>
              <XAxis
                type="number"
                tickLine={false}
                axisLine={false}
                style={{
                  fontSize: 14,
                }}
                tick={{ fill: theme.palette.text.disabled }}
              />
              <YAxis
                type="category"
                dataKey="list_name"
                tickSize={0}
                width={150}
                axisLine={{ stroke: theme.palette.text.disabled }}
                style={{
                  fontSize: 14,
                }}
                tickMargin={20}
                tick={{ fill: theme.palette.text.disabled }}
              />
              <defs>
                <linearGradient
                  key="responseRateGradient"
                  id="responseRateGradient"
                  x1="1"
                  y1="0"
                  x2="0"
                  y2="0"
                >
                  <stop offset="5%" stopColor={theme.palette.primary.main} />
                  <stop offset="95%" stopColor={theme.palette.primary.light} />
                </linearGradient>
              </defs>
              <Bar
                dataKey="legal_cases"
                fill="url(#responseRateGradient)"
                radius={4}
                maxBarSize={28}
              >
                <LabelList
                  position="right"
                  fill={theme.palette.text.secondary}
                />
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </ChartContainer>
        <ChartContainer
          width="50%"
          height="60vh"
          display="flex"
          flexDirection="column"
          gap={2}
        >
          <Typography variant="subtitle2">Open Legal Cases per Type</Typography>
          <ResponsiveContainer width="100%" height="80%">
            <PieChart>
              <Pie
                data={pieChartData}
                dataKey="value"
                nameKey="label"
                cx="50%"
                cy="50%"
                innerRadius={60}
                outerRadius={100}
                legendType="circle"
                paddingAngle={5}
                cornerRadius={4}
                stroke="none"
                activeShape={(props: SectorProps) => (
                  <Sector
                    {...props}
                    fillOpacity={0.8}
                    style={{ cursor: "pointer" }}
                  />
                )}
              >
                {pieChartData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={theme.chartPalette[index]}
                    onClick={() =>
                      window.open(
                        `https://trello.com/b/CZJmiYCx/legal-cases-investis-portfolio/table?filter=label:${encodeURIComponent(
                          entry.label
                        )}`,
                        "__blank"
                      )
                    }
                  />
                ))}
              </Pie>
              <Legend
                align="right"
                verticalAlign="middle"
                layout="vertical"
                iconSize={12}
                formatter={(value) => (
                  <span
                    style={{
                      color: theme.palette.text.secondary,
                      fontSize: "12px",
                    }}
                  >
                    {value}
                  </span>
                )}
              />
              <Tooltip content={<SimpleChartTooltip title="Case Count" />} />
            </PieChart>
          </ResponsiveContainer>
          <Typography
            marginBottom={4}
            marginX="auto"
            variant="body2"
            color={theme.palette.text.secondary}
          >
            Click on a section to open in Trello
          </Typography>
        </ChartContainer>
      </Box>
    </Box>
  );
};

export default LegalCases;
