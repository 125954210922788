import React, { useContext } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Consumption from "./components/Consumption/Consumption";
import Home from "./components/Home/Home";
import NotFound from "./components/NotFound/NotFound";

import Guarantee from "./components/Guarantee/Guarantee";
import LegalCases from "./components/LegalCases/LegalCases";

import GuaranteeProvider from "./context/GuaranteeContext";
import OpenLegalCasesProvider from "./context/OpenLegalCasesContext";
import CasesStatusProvider from "./context/CaseStatus";
import ConsumptionProvider from "./context/ConsumptionContext";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { User } from "firebase/auth";
import PortfolioHealth from "./components/PortfolioHealth/PortfolioHealth";
import OccupancyProvider from "./context/OccupancyContext";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import Reports from "./components/Reports/Reports";
import { Box, CssBaseline, ThemeProvider, styled } from "@mui/material";
import BudgetProvider from "./context/BudgetContext";
import PortfolioHealthProvider from "./context/PortfolioHealthContext";
import TechnicalHealthProvider from "./context/TechnicalHealthContext";
import RentCollectedProvider from "./context/RentCollectedContext";
import RentIncreasesProvider from "./context/RentIncreasesContext";
import CpiIncreasesProvider from "./context/CpiIncreasesContext";
import OccupancyDetails from "./components/PortfolioHealth/OccupancyDetails";
import RentCollectedDetails from "./components/PortfolioHealth/RentCollectedDetails";
import RentIncreaseDetails from "./components/PortfolioHealth/RentIncreaseDetails";
import CpiIncreaseDetails from "./components/PortfolioHealth/CpiIncreaseDetails";
import BudgetDetails from "./components/PortfolioHealth/BudgetDetails/BudgetDetails";
import RelocationsProvider from "./context/RelocationsContext";
import BuildingsProvider from "./context/BuildingsContext";
import TechnicalHealthDetails from "./components/PortfolioHealth/TechnicalHealthDetails";
import SecretsProvider from "./context/SecretsContext";
import { useTheme } from "./useTheme";
import Start from "./components/Login/Start";
import { AuthContext } from "./context/AuthContext";

const App: React.FC = () => {
  const { user, userAcceptedDisclaimer } = useContext(AuthContext);
  const theme = useTheme();

  return (
    <BrowserRouter>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <MainContainer width="100vw" height="100vh" display="flex">
            <Routes>
              <Route path="/" element={<Start />}></Route>
              <Route
                path="/home"
                element={
                  <PrivateRoute>
                    <PortfolioHealthProvider>
                      <Home />
                    </PortfolioHealthProvider>
                  </PrivateRoute>
                }
              ></Route>
              <Route
                path="/consumption"
                element={
                  <PrivateRoute>
                    <ConsumptionProvider>
                      <Consumption
                        user={user as User}
                        disclaimerConsent={userAcceptedDisclaimer}
                      />
                    </ConsumptionProvider>
                  </PrivateRoute>
                }
              ></Route>
              <Route
                path="/legal-cases"
                element={
                  <PrivateRoute>
                    <OpenLegalCasesProvider>
                      <CasesStatusProvider>
                        <LegalCases />
                      </CasesStatusProvider>
                    </OpenLegalCasesProvider>
                  </PrivateRoute>
                }
              ></Route>
              <Route path="/portfolio-health">
                <Route
                  index
                  element={
                    <PrivateRoute>
                      <GuaranteeProvider>
                        <OccupancyProvider>
                          <BudgetProvider>
                            <TechnicalHealthProvider>
                              <RentCollectedProvider>
                                <PortfolioHealthProvider>
                                  <CpiIncreasesProvider>
                                    <RentIncreasesProvider>
                                      <PortfolioHealth />
                                    </RentIncreasesProvider>
                                  </CpiIncreasesProvider>
                                </PortfolioHealthProvider>
                              </RentCollectedProvider>
                            </TechnicalHealthProvider>
                          </BudgetProvider>
                        </OccupancyProvider>
                      </GuaranteeProvider>
                    </PrivateRoute>
                  }
                />
                <Route
                  path="occupancy"
                  element={
                    <PrivateRoute>
                      <OccupancyProvider>
                        <OccupancyDetails />
                      </OccupancyProvider>
                    </PrivateRoute>
                  }
                />
                <Route
                  path="rent-collected"
                  element={
                    <PrivateRoute>
                      <RentCollectedProvider>
                        <RentCollectedDetails />
                      </RentCollectedProvider>
                    </PrivateRoute>
                  }
                />
                <Route
                  path="rent-increases"
                  element={
                    <PrivateRoute>
                      <RelocationsProvider>
                        <RentIncreasesProvider>
                          <RentIncreaseDetails />
                        </RentIncreasesProvider>
                      </RelocationsProvider>
                    </PrivateRoute>
                  }
                />
                <Route
                  path="cpi-rent-increases"
                  element={
                    <PrivateRoute>
                      <CpiIncreasesProvider>
                        <CpiIncreaseDetails />
                      </CpiIncreasesProvider>
                    </PrivateRoute>
                  }
                />
                <Route
                  path="budget"
                  element={
                    <PrivateRoute>
                      <SecretsProvider>
                        <BudgetProvider>
                          <BuildingsProvider>
                            <BudgetDetails />
                          </BuildingsProvider>
                        </BudgetProvider>
                      </SecretsProvider>
                    </PrivateRoute>
                  }
                />
                <Route
                  path="24h-guarantee"
                  element={
                    <PrivateRoute>
                      <GuaranteeProvider>
                        <Guarantee />
                      </GuaranteeProvider>
                    </PrivateRoute>
                  }
                ></Route>
                <Route
                  path="technical-health"
                  element={
                    <PrivateRoute>
                      <TechnicalHealthProvider>
                        <TechnicalHealthDetails />
                      </TechnicalHealthProvider>
                    </PrivateRoute>
                  }
                ></Route>
              </Route>
              <Route
                path="/occupancy"
                element={
                  <PrivateRoute>
                    <OccupancyProvider>
                      <></>
                    </OccupancyProvider>
                  </PrivateRoute>
                }
              />
              <Route
                path="/reports"
                element={
                  <PrivateRoute>
                    <Reports />
                  </PrivateRoute>
                }
              />

              {/* When user first logs in, we should serve a licence agreement */}
              {/* there’s no guarantee that the data here are gonna be correct, 
              this is not an investment advice etc etc */}

              {/* // Catch-all route for undefined paths */}
              <Route path="*" element={<NotFound />}></Route>
            </Routes>
          </MainContainer>
        </ThemeProvider>
      </LocalizationProvider>
    </BrowserRouter>
  );
};

const MainContainer = styled(Box)`
  background: ${({ theme }) => theme.background};
`;

export default App;
