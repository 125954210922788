import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import { createContext, useEffect, useState } from "react";
import { db } from "../firebaseConfig";
import Loading from "../components/Loading/Loading";

type RentCollectedData = {
  rentDueMonthly: {
    [month: string]: number;
  };
  rentCollectedMonthly: {
    [month: string]: {
      [day: string]: number;
    };
  };
  currentRentCollectedPerc: number;
  currentRentDue: number;
  currentRentMissing: number;
  tenancies: number;
};

const initialValue: RentCollectedData = {
  rentDueMonthly: {},
  rentCollectedMonthly: {},
  currentRentCollectedPerc: 0,
  currentRentDue: 0,
  currentRentMissing: 0,
  tenancies: 0,
};

export const RentCollectedContext =
  createContext<RentCollectedData>(initialValue);

type RentCollectedProviderProps = {
  children: React.ReactNode;
};

const RentCollectedProvider: React.FC<RentCollectedProviderProps> = ({
  children,
}) => {
  const [data, setData] = useState<RentCollectedData>(initialValue);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const dueRef = collection(db, "investis-rent-due-monthly");
      const dueSnap = await getDocs(dueRef);
      const collectedRef = collection(db, "investis-rent-collected-daily");
      const collectedSnap = await getDocs(collectedRef);
      const docRef = doc(
        db,
        "metrics",
        "rent_collected",
        new Date().getFullYear().toString(),
        new Date().getMonth().toString().padStart(2, "0")
      );
      const docSnap = await getDoc(docRef);

      if (dueSnap.empty || collectedSnap.empty) {
        return;
      }

      const rentDueMonthly = Object.fromEntries(
        dueSnap.docs.map((doc) => [doc.id, doc.data().rent_due])
      );

      const rentCollectedMonthly = Object.fromEntries(
        collectedSnap.docs.map((doc) => [
          doc.id,
          Object.fromEntries(
            Object.entries(doc.data() as { [day: string]: number }).sort(
              (a, b) => new Date(a[0]).getTime() - new Date(b[0]).getTime()
            )
          ),
        ])
      );

      const currentRentDue = Object.values(rentDueMonthly).at(-2);

      const previousMonthRentCollected =
        Object.values(rentCollectedMonthly).at(-2);

      if (!previousMonthRentCollected) {
        return;
      }

      const currentRentCollected = Object.values(previousMonthRentCollected).at(
        -1
      );

      const currentRentCollectedPerc =
        ((currentRentCollected ?? 0) / currentRentDue) * 100;

      const currentRentMissing = currentRentDue - (currentRentCollected ?? 0);

      const tenancies = docSnap.data()?.from_tenancies ?? 0;

      setData({
        rentDueMonthly,
        rentCollectedMonthly,
        currentRentCollectedPerc,
        currentRentDue,
        currentRentMissing,
        tenancies,
      });
      setIsLoading(false);
    };
    fetchData();
  }, []);
  return (
    <RentCollectedContext.Provider value={data}>
      {isLoading ? <Loading /> : children}
    </RentCollectedContext.Provider>
  );
};

export default RentCollectedProvider;
