import {
  Box,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  MenuItem,
  Select,
  styled,
  Typography,
  useTheme,
} from '@mui/material';
import { FC, useContext, useMemo, useRef, useState } from 'react';
import PageTitle from '../../PageTitle/PageTitle';
import {
  Bar,
  Cell,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { BudgetContext, BudgetData } from '../../../context/BudgetContext';
import { formatDate } from '../../../helpers/DateHelpers';
import ComboChartTooltip from '../../chart/ComboChartTooltip';
import { formatNumber } from '../../../helpers/NumberHelpers';
import { BuildingsContext } from '../../../context/BuildingsContext';
import { GridColDef } from '@mui/x-data-grid';
import { APIProvider, Map } from '@vis.gl/react-google-maps';
import ClusteredBuildingMarkers from './ClusteredBuildingMarkers';
import MetricCardSimple from '../../MetricCardSimple/MetricCardSimple';
import ModeSwitch from '../../ModeSwitch/ModeSwitch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWandMagicSparkles } from '@fortawesome/free-solid-svg-icons';
import BudgetRecommendationsModal from './BudgetRecommendationsModal';
import { SecretsContext } from '../../../context/SecretsContext';
import DataGrid from '../../DataGrid/DataGrid';
import ChartContainer from '../../chart/ChartContainer';
import { useAppSelector } from '../../../store';
import { selectMode } from '../../../store/ui.slice';

const BudgetDetails: FC = () => {
  const theme = useTheme();
  const budgetData = useContext(BudgetContext);
  const buildingsData = useContext(BuildingsContext);
  const secrets = useContext(SecretsContext);
  const mode = useAppSelector(selectMode);

  const [cumulative, setCumulative] = useState(true);
  const [showProjected, setShowProjected] = useState(true);
  const [showLastYear, setShowLastYear] = useState(false);
  const [selectedBuilding, setSelectedBuilding] = useState<string>('all');
  const [graphMode, setGraphMode] = useState<string>('%');
  const [showRecommendations, setShowRecommendations] = useState(false);

  const dataGridRef = useRef<HTMLDivElement>(null);

  const handleScrollToDataGrid = () => {
    dataGridRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const chartData = useMemo(() => {
    const thisYear = new Date().getFullYear();
    const previousYear = thisYear - 1;

    const tooltipLabels = {
      thisYearSpent: thisYear.toString(),
      lastYearSpent: previousYear.toString(),
      thisYearTotal: `${thisYear} total`,
    };

    const tooltipValueFormatters = {
      thisYearSpent: (value: number) =>
        formatNumber({ value, options: { round: true, format: ', ' } }),
      lastYearSpent: (value: number) =>
        formatNumber({ value, options: { round: true, format: ', ' } }),
      thisYearTotal: (value: number) =>
        formatNumber({ value, options: { round: true, format: ', ' } }),
    };
    const seriesColors = {
      thisYearSpent: 'primary',
      lastYearSpent: 'secondary',
      thisYearTotal: 'error',
    } as {
      [key: string]: 'primary' | 'secondary' | 'error';
    };
    const seriesIcons = {
      thisYearSpent: 'square',
      lastYearSpent: 'square',
      thisYearTotal: 'line',
    };

    const thisYearValues = getDataForYear(
      thisYear,
      budgetData,
      cumulative,
      selectedBuilding,
      graphMode
    )?.map((entry, index) => {
      if (!showProjected) {
        return {
          ...entry,
          value1: index >= new Date().getMonth() ? 0 : entry.value1,
        };
      }
      return entry;
    });

    const lastYearValues = getDataForYear(
      previousYear,
      budgetData,
      cumulative,
      selectedBuilding,
      graphMode
    );

    if (!thisYearValues) {
      return undefined;
    }

    if (showLastYear) {
      if (!lastYearValues) {
        return undefined;
      }
      const spentValues = thisYearValues
        .map((entry) => entry.value1 as number)
        .concat(lastYearValues.map((entry) => entry.value1 as number));

      const domain = [
        Math.floor(
          Math.min(...spentValues) / (graphMode === '%' ? 10 : 10000)
        ) * (graphMode === '%' ? 10 : 10000),
        Math.ceil(
          Math.max(
            ...(thisYearValues
              .map((entry) => [entry.value1, entry.value2])
              .flat() as number[]),
            ...(lastYearValues
              .map((entry) => [entry.value1, entry.value2])
              .flat() as number[])
          ) / (graphMode === '%' ? 10 : 10000)
        ) * (graphMode === '%' ? 10 : 10000),
      ];

      const ticks = [domain[0], domain[0] + domain[1] / 2, domain[1]];

      return {
        data: lastYearValues.map((entry, index) => {
          const month = entry.label.split('-')[0];
          return {
            label: month,
            thisYearSpent: thisYearValues[index]?.value1 ?? 0,
            lastYearSpent: entry.value1,
            thisYearTotal: thisYearValues[index]?.value2 ?? 0,
            lastYearTotal: entry.value2,
          };
        }),
        tooltipLabels,
        tooltipValueFormatters,
        seriesColors,
        tooltipSeriesColors: seriesColors,
        seriesIcons,
        domain,
        ticks,
      };
    }

    const spentValues = thisYearValues.map((entry) => entry.value1 as number);
    const domain = [
      Math.floor(Math.min(...spentValues) / (graphMode === '%' ? 10 : 10000)) *
        (graphMode === '%' ? 10 : 10000),
      Math.ceil(
        Math.max(
          ...(thisYearValues
            .map((entry) => [entry.value1, entry.value2])
            .flat() as number[])
        ) / (graphMode === '%' ? 10 : 10000)
      ) * (graphMode === '%' ? 10 : 10000),
    ];

    const ticks = [domain[0], domain[0] + domain[1] / 2, domain[1]];

    return {
      data: thisYearValues.map((entry) => ({
        label: entry.label,
        thisYearSpent: entry.value1,
        thisYearTotal: entry.value2,
      })),
      tooltipLabels,
      tooltipValueFormatters,
      seriesColors,
      seriesIcons,
      domain,
      ticks,
    };
  }, [
    budgetData,
    cumulative,
    graphMode,
    selectedBuilding,
    showLastYear,
    showProjected,
  ]);

  const columns: GridColDef[] = [
    {
      field: 'address',
      headerName: 'Address',
      flex: 4,
    },
    {
      field: 'budget',
      headerName: 'Budget',
      flex: 1.5,
      renderCell: (params) =>
        formatNumber({
          value: params.value,
          options: { round: true, format: ', ' },
        }) + ' CHF',
    },
    {
      field: 'used',
      headerName: 'Budget Used',
      flex: 1.5,
      renderCell: (params) =>
        formatNumber({
          value: params.value,
          options: { round: true, format: ', ' },
        }) + ' CHF',
    },
    {
      field: 'percent',
      headerName: 'Budget Used %',
      flex: 1.5,
      renderCell: (params) => `${params.value.toFixed(2)}%`,
    },
    {
      field: 'remaining',
      headerName: 'Budget Remaining',
      flex: 1.5,
      renderCell: (params) =>
        formatNumber({
          value: params.value,
          options: { round: true, format: ', ' },
        }) + ` CHF`,
    },
    {
      field: 'projection',
      headerName: 'Year End Projection',
      flex: 1.5,
      renderCell: (params) => (
        <Box display="flex" justifyContent="flex-end" gap={1}>
          <span
            style={{
              color: theme.palette.error.main,
              fontWeight: 'bold',
              fontSize: 20,
            }}
          >
            {params.value > 100 ? '!' : ''}
          </span>
          {formatNumber({
            value: params.value,
            options: { format: ', ' },
          }) + '%'}
        </Box>
      ),
    },
  ];

  const rows = useMemo(() => {
    if (
      !Object.keys(budgetData.monthly).length ||
      !Object.keys(budgetData.total).length ||
      !Object.keys(buildingsData).length
    ) {
      return [];
    }
    const totalKey = `${new Date().getFullYear().toString().slice(-2)}TOTAL`;
    const monthKey = `${new Date()
      .getFullYear()
      .toString()
      .slice(-2)}M${new Date().getMonth().toString().padStart(2, '0')}`;

    const projectionKey = `${new Date()
      .getFullYear()
      .toString()
      .slice(-2)}M12PROJECTION`;

    if (!budgetData.monthly[monthKey]) {
      return [];
    }

    const totals = Object.entries(budgetData.total[totalKey]);
    const spent = Object.entries(budgetData.monthly[monthKey]);
    const projected = Object.entries(budgetData.monthly[projectionKey]);

    if (!totals.every(([key]) => spent.some(([k]) => k === key))) {
      return [];
    }

    return totals.map(([key, value]) => {
      const remaining = value - value * spent.find(([k]) => k === key)![1];
      return {
        id: key,
        address: buildingsData[key].address,
        budget: value,
        used: value * spent.find(([k]) => k === key)![1],
        percent: spent.find(([k]) => k === key)![1] * 100,
        remaining,
        over: remaining < 0,
        nearing: remaining < 10000,
        projection: projected.find(([k]) => k === key)![1] * 100,
      };
    });
  }, [budgetData.monthly, budgetData.total, buildingsData]);

  const mapCenter = {
    lat:
      Object.entries(buildingsData)
        .filter(([key]) =>
          Object.keys(Object.values(budgetData.monthly).at(-1) ?? []).includes(
            key
          )
        )
        .reduce((acc, [, cur]) => acc + cur.lat, 0) /
      Object.entries(buildingsData).filter(([key]) =>
        Object.keys(Object.values(budgetData.monthly).at(-1) ?? []).includes(
          key
        )
      ).length,
    lng:
      Object.entries(buildingsData)
        .filter(([key]) =>
          Object.keys(Object.values(budgetData.monthly).at(-1) ?? []).includes(
            key
          )
        )
        .reduce((acc, [, cur]) => acc + cur.lng, 0) /
      Object.entries(buildingsData).filter(([key]) =>
        Object.keys(Object.values(budgetData.monthly).at(-1) ?? []).includes(
          key
        )
      ).length,
  };

  const markerColors = useMemo(
    () =>
      Object.fromEntries(
        Object.entries(
          Object.entries(budgetData.monthly)
            .filter(([key]) => !key.includes('PROJECTION'))
            .at(-1)?.[1] ?? []
        ).map(([key, value]) => [
          key,
          value > 1
            ? theme.palette.secondary.main
            : value > 0.9
            ? theme.palette.secondary.light
            : theme.palette.primary.main,
        ])
      ),
    [
      budgetData.monthly,
      theme.palette.primary.main,
      theme.palette.secondary.light,
      theme.palette.secondary.main,
    ]
  );

  const filteredBuildings = useMemo(() => {
    const buildingEntries = Object.entries(buildingsData);
    const budgetKeys = Object.keys(
      budgetData.total[`${new Date().getFullYear().toString().slice(-2)}TOTAL`]
    );
    const filteredEntries = buildingEntries
      .filter(([key]) => budgetKeys.includes(key))
      .map(([key, value]) => [key, { ...value, color: markerColors[key] }]);

    return Object.fromEntries(filteredEntries);
  }, [budgetData.total, buildingsData, markerColors]);

  const percentageUsed = useMemo(() => {
    const key = `${new Date().getFullYear().toString().slice(-2)}M${new Date()
      .getMonth()
      .toString()
      .padStart(2, '0')}`;
    const totalValues =
      budgetData.total[`${new Date().getFullYear().toString().slice(-2)}TOTAL`];

    if (!budgetData.monthly[key]) {
      return;
    }
    const spent = Object.entries(budgetData.monthly[key]).reduce(
      (acc, [key, value]) => acc + value * totalValues[key],
      0
    );

    const total = Object.values(totalValues).reduce(
      (acc, value) => acc + value,
      0
    );

    return formatNumber({
      value: (spent / total) * 100,
      options: { round: true },
    });
  }, [budgetData.monthly, budgetData.total]);

  const buildingsOverBudget = useMemo(() => {
    const monthKey = `${new Date()
      .getFullYear()
      .toString()
      .slice(-2)}M${new Date().getMonth().toString().padStart(2, '0')}`;

    if (!budgetData.monthly[monthKey]) {
      return;
    }

    const over = Object.values(budgetData.monthly[monthKey]).filter(
      (value) => value > 1
    );

    return over.length;
  }, [budgetData.monthly]);

  const buildingsOnPathToOverBudget = useMemo(() => {
    const projections = Object.entries(budgetData.monthly).filter(([key]) =>
      key.includes('PROJECTION')
    );

    if (!projections.length) {
      return 0;
    }

    const lastMonthValues = projections.at(-1)![1];

    const over = Object.values(lastMonthValues).filter((value) => value > 1);

    return over.length;
  }, [budgetData.monthly]);

  const budgetSaved = useMemo(() => {
    const lastYearTotal =
      budgetData.total[
        `${(new Date().getFullYear() - 1).toString().slice(-2)}TOTAL`
      ];
    const lastYearSpent =
      budgetData.monthly[
        `${(new Date().getFullYear() - 1).toString().slice(-2)}M12`
      ];

    const total = Object.values(lastYearTotal).reduce(
      (acc, cur) => acc + cur,
      0
    );
    const spent = Object.entries(lastYearSpent).reduce(
      (acc, [key, value]) => acc + value * lastYearTotal[key],
      0
    );

    return formatNumber({
      value: total - spent,
      options: { round: true, format: 'K' },
    });
  }, [budgetData.monthly, budgetData.total]);

  const recommendations = useMemo(() => {
    const changes = Object.values(budgetData.recommendations).some(
      (value) => value.recommendation_type === 'reallocate'
    );
    return {
      changes,
      data: budgetData.recommendations,
    };
  }, [budgetData.recommendations]);

  if (!chartData) {
    return null;
  }

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Box display="flex" alignItems="center" gap={3}>
        <PageTitle title="Budget" />
        <RecommendationsBanner
          changes={recommendations.changes}
          display="flex"
          alignItems="center"
          gap={1}
          onClick={() => setShowRecommendations(true)}
        >
          <FontAwesomeIcon icon={faWandMagicSparkles} />
          <Typography variant="body2" color="white">
            {new Date().getFullYear() + 1} AI Recommendations
          </Typography>
        </RecommendationsBanner>
      </Box>
      <Box display="flex" flexDirection="column" gap={2}>
        <Box
          display="grid"
          gridTemplateColumns="repeat(4, 1fr)"
          gap={3}
          width="100%"
        >
          <MetricCardSimple
            title="Budget Used"
            unit="%"
            value={percentageUsed ?? 0}
            caption={`as of ${formatDate(new Date(), { day: false })}`}
            disableHover
          />
          <MetricCardSimple
            title="Buildings Over Budget Today"
            value={buildingsOverBudget ?? 0}
            caption={`as of ${formatDate(new Date(), { day: false })}`}
            onClick={handleScrollToDataGrid}
          />
          <MetricCardSimple
            title="Buildings Over Budget by End of Year"
            value={buildingsOnPathToOverBudget}
            caption="buildings on path to exceed budget this year"
            onClick={handleScrollToDataGrid}
          />
          <MetricCardSimple
            title="Budget Saved"
            unit="CHF"
            value={budgetSaved}
            caption="amount left from last year's budget"
            disableHover
          />
        </Box>
        <Typography variant="subtitle2" fontWeight="bold">
          Budget Consumption
        </Typography>
        <FiltersContainer display="flex" gap={2} alignItems="center">
          <Typography variant="body2" color="GrayText">
            Filters:
          </Typography>
          <ModeSwitch
            modes={['%', '#']}
            currentMode={graphMode}
            width="64px"
            height="32px"
            onModeClick={setGraphMode}
          />
          <FormControlLabel
            control={
              <Checkbox
                title="Show previous year"
                checked={showLastYear}
                onChange={() => setShowLastYear(!showLastYear)}
                color="secondary"
              />
            }
            label={
              <Typography variant="body2">
                {new Date().getFullYear() - 1}
              </Typography>
            }
          />
          <FormControlLabel
            control={
              <Checkbox
                title="Show projected"
                checked={showProjected}
                onChange={() => setShowProjected(!showProjected)}
                color="secondary"
              />
            }
            label={<Typography variant="body2">Projected</Typography>}
          />
          <FormControlLabel
            control={
              <Checkbox
                title="Cumulative"
                checked={cumulative}
                onChange={() => setCumulative(!cumulative)}
                color="secondary"
              />
            }
            label={<Typography variant="body2">Cumulative</Typography>}
          />
          <Select
            size="small"
            value={selectedBuilding}
            sx={{
              fontSize: theme.typography.subtitle2.fontSize,
              marginLeft: 1,
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
            }}
            onChange={(event) =>
              setSelectedBuilding(event.target.value as string)
            }
          >
            {[
              <MenuItem
                key="all"
                value="all"
                sx={{
                  fontSize: theme.typography.subtitle2.fontSize,
                  '&.Mui-selected': {
                    backgroundColor: `${theme.palette.primary.light.replace(
                      ')',
                      ', 0.3)'
                    )} !important`,
                  },
                }}
              >
                All Buildings
              </MenuItem>,
              ...Object.entries(buildingsData)
                .filter(([key]) => {
                  const totalKey = `${new Date()
                    .getFullYear()
                    .toString()
                    .slice(-2)}TOTAL`;
                  const buildings = Object.keys(budgetData.total[totalKey]);
                  return buildings.includes(key);
                })
                .map(([key, value]) => (
                  <MenuItem
                    key={key}
                    value={key}
                    sx={{
                      fontSize: theme.typography.subtitle2.fontSize,
                      '&.Mui-selected': {
                        backgroundColor: `${theme.palette.primary.light.replace(
                          ')',
                          ', 0.3)'
                        )} !important`,
                      },
                    }}
                  >
                    {value.address}
                  </MenuItem>
                )),
            ]}
          </Select>
        </FiltersContainer>
        <ChartContainer width="100%" height="50vh">
          <ResponsiveContainer width="100%" height="100%">
            <ComposedChart
              data={chartData.data}
              margin={{ right: 40, top: 20, bottom: -10 }}
            >
              <defs>
                {Object.entries(chartData.seriesColors)
                  .filter(([key]) => key.includes('Spent'))
                  .map(([key, color]) => (
                    <linearGradient
                      key={key}
                      id={`color${color}`}
                      x1="0"
                      y1="0"
                      x2="0"
                      y2="1"
                    >
                      <stop offset="5%" stopColor={theme.palette[color].main} />
                      <stop
                        offset="95%"
                        stopColor={theme.palette[color].light}
                      />
                    </linearGradient>
                  ))}
                <pattern
                  id="diagonalHatch"
                  patternUnits="userSpaceOnUse"
                  width="4"
                  height="4"
                >
                  <path
                    d="M-1,1 l2,-2
           M0,4 l4,-4
           M3,5 l2,-2"
                    style={{
                      stroke: theme.palette.primary.light,
                      strokeWidth: 1,
                    }}
                  />
                </pattern>
              </defs>
              <Bar dataKey="thisYearSpent" radius={4}>
                {chartData.data.map((entry, index) => (
                  <Cell
                    key={entry.label}
                    fill={
                      index > new Date().getMonth() - 1
                        ? 'url(#diagonalHatch)'
                        : `url(#color${chartData.seriesColors.thisYearSpent})`
                    }
                    fillOpacity={0.8}
                  />
                ))}
              </Bar>
              {showLastYear && (
                <Bar
                  dataKey="lastYearSpent"
                  fill={`url(#color${chartData.seriesColors.lastYearSpent})`}
                  fillOpacity={0.8}
                  radius={4}
                />
              )}
              <XAxis
                dataKey="label"
                axisLine={false}
                tickLine={false}
                tick={{ fill: theme.palette.text.disabled }}
              />
              <YAxis
                tickFormatter={(value) =>
                  formatNumber({
                    value: value as number,
                    options: { round: true, format: 'K' },
                  })
                }
                ticks={chartData.ticks}
                axisLine={false}
                tickLine={false}
                domain={chartData.domain}
                tick={{ fill: theme.palette.text.disabled }}
              />
              <Line
                dataKey="thisYearTotal"
                stroke={theme.palette.error.light}
                dot={false}
                strokeWidth={1}
              />
              <Tooltip
                content={
                  <ComboChartTooltip
                    labels={chartData.tooltipLabels}
                    valueFormatters={chartData.tooltipValueFormatters}
                    unit={graphMode === '%' ? '%' : 'CHF'}
                    seriesColors={chartData.tooltipSeriesColors}
                    seriesIcons={chartData.seriesIcons}
                  />
                }
              />
              <Legend
                content={({ payload }) => (
                  <Box width="100%" display="flex" justifyContent="center">
                    <ul>
                      {payload
                        ?.map((entry, index) => (
                          <li
                            key={`item-${index}`}
                            style={{ display: 'inline', marginRight: 10 }}
                          >
                            <Box
                              display="inline-flex"
                              alignItems="center"
                              gap={0.5}
                            >
                              {entry.value === 'thisYearSpent' ? (
                                <Box
                                  display="inline"
                                  height={12}
                                  width={12}
                                  borderRadius={1}
                                  sx={{
                                    background: theme.palette.primary.light,
                                  }}
                                />
                              ) : entry.value === 'lastYearSpent' ? (
                                <Box
                                  display="inline"
                                  height={12}
                                  width={12}
                                  borderRadius={1}
                                  sx={{
                                    background: theme.palette.secondary.main,
                                  }}
                                />
                              ) : (
                                <svg width="12" height="12" viewBox="0 0 32 32">
                                  <title></title>
                                  <desc></desc>
                                  <path
                                    strokeWidth="4"
                                    fill="none"
                                    stroke={theme.palette.error.light}
                                    d="M0,16h10.666666666666666
            A5.333333333333333,5.333333333333333,0,1,1,21.333333333333332,16
            H32M21.333333333333332,16
            A5.333333333333333,5.333333333333333,0,1,1,10.666666666666666,16"
                                  ></path>
                                </svg>
                              )}
                              <Typography
                                component="span"
                                variant="body2"
                                color="GrayText"
                              >
                                {entry.value === 'thisYearSpent'
                                  ? new Date().getFullYear().toString()
                                  : entry.value === 'lastYearSpent'
                                  ? (new Date().getFullYear() - 1).toString()
                                  : entry.value === 'thisYearTotal'
                                  ? `${new Date().getFullYear()} Total`
                                  : `${new Date().getFullYear() - 1} Total`}
                              </Typography>
                            </Box>
                          </li>
                        ))
                        .toSpliced(
                          1,
                          0,
                          showProjected ? (
                            <li
                              key={`item-projected`}
                              style={{ display: 'inline', marginRight: 10 }}
                            >
                              <Box
                                display="inline-flex"
                                alignItems="center"
                                gap={0.5}
                              >
                                <Box
                                  display="inline"
                                  height={12}
                                  width={12}
                                  borderRadius={1}
                                  sx={{
                                    background: `repeating-linear-gradient(-45deg, ${theme.palette.primary.light}, ${theme.palette.primary.light} 1px, transparent 2px, transparent 3px)`,
                                  }}
                                />
                                <Typography
                                  component="span"
                                  variant="body2"
                                  color="GrayText"
                                >
                                  {new Date().getFullYear()} Projected
                                </Typography>
                              </Box>
                            </li>
                          ) : (
                            <></>
                          )
                        )}
                    </ul>
                  </Box>
                )}
                wrapperStyle={{ paddingTop: 20 }}
              />
            </ComposedChart>
          </ResponsiveContainer>
        </ChartContainer>
      </Box>
      <Typography variant="subtitle2" fontWeight="bold">
        Buildings Map
      </Typography>
      <Box
        height="400px"
        borderRadius={3}
        overflow="hidden"
        marginBottom={2}
        sx={{
          '.gm-style-iw-c, .gm-style-iw-d, .gm-style-iw-tc:after': {
            background: 'none',
            overflow: 'hidden !important',
            padding: '0 !important',
          },
        }}
      >
        {secrets.maps_api_key ? (
          <APIProvider apiKey={secrets.maps_api_key}>
            <Map
              mapId={mode === 'dark' ? 'a4fcde292b8079fc' : 'aea41f9a00589f25'}
              defaultZoom={13}
              disableDefaultUI
              defaultCenter={mapCenter}
              clickableIcons={false}
              scrollwheel={false}
            >
              <ClusteredBuildingMarkers buildings={filteredBuildings} />
            </Map>
          </APIProvider>
        ) : (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
            width="100%"
          >
            <CircularProgress />
          </Box>
        )}
      </Box>
      <Typography variant="subtitle2" fontWeight="bold">
        Budget by building
      </Typography>
      <DataGrid
        height="400px"
        ref={dataGridRef}
        columns={columns}
        rows={rows}
        initialState={{
          sorting: {
            sortModel: [{ field: 'percent', sort: 'desc' }],
          },
        }}
        getRowClassName={(params) =>
          params.row.over ? 'over' : params.row.nearing ? 'nearing' : ''
        }
        disableColumnMenu={true}
        disableRowSelectionOnClick={true}
        disableDensitySelector={true}
        hideFooter={true}
      />
      <BudgetRecommendationsModal
        open={showRecommendations}
        recommendations={recommendations.data}
        onClose={() => setShowRecommendations(false)}
      />
    </Box>
  );
};

const getDataForYear = (
  year: number,
  data: BudgetData,
  cumulative: boolean,
  building: string,
  graphMode: string
) => {
  if (!Object.keys(data.monthly).length || !Object.keys(data.total).length) {
    return;
  }
  const yearEntries = Object.entries(data.monthly).filter(
    ([key]) =>
      key.includes(`${year.toString().slice(-2)}M`) &&
      !key.includes('PROJECTION')
  );
  const projectedEntries = Object.entries(data.monthly).filter(
    ([key]) =>
      key.includes(`${year.toString().slice(-2)}M`) &&
      key.includes('PROJECTION')
  );
  const yearTotals = data.total[`${year.toString().slice(-2)}TOTAL`];

  const cumulativeMonthly =
    building === 'all'
      ? [
          ...yearEntries.map(
            ([key, value]) =>
              [
                key,
                Object.entries(value).reduce(
                  (acc, [key, value]) => acc + value * yearTotals[key],
                  0
                ) /
                  (graphMode === '%'
                    ? Object.values(yearTotals).reduce(
                        (acc, value) => acc + value,
                        0
                      ) / 100
                    : 1),
              ] as [string, number]
          ),
          ...projectedEntries.map(
            ([key, value]) =>
              [
                key.replace('PROJECTION', ''),
                Object.entries(value).reduce(
                  (acc, [key, value]) => acc + value * yearTotals[key],
                  0
                ) /
                  (graphMode === '%'
                    ? Object.values(yearTotals).reduce(
                        (acc, value) => acc + value,
                        0
                      ) / 100
                    : 1),
              ] as [string, number]
          ),
        ]
      : [
          ...yearEntries.map(
            ([key, value]) =>
              [
                key,
                value[building] *
                  (graphMode === '%' ? 100 : yearTotals[building]),
              ] as [string, number]
          ),
          ...projectedEntries.map(
            ([key, value]) =>
              [
                key,
                value[building] *
                  (graphMode === '%' ? 100 : yearTotals[building]),
              ] as [string, number]
          ),
        ];

  const monthly = cumulativeMonthly.map(([key, value], index) => {
    if (index === 0) {
      return [key, value];
    }
    return [key, value - cumulativeMonthly[index - 1][1]];
  });

  const yearTotal =
    graphMode === '%'
      ? 100
      : building === 'all'
      ? Object.values(yearTotals).reduce((acc, value) => acc + value, 0)
      : yearTotals[building];

  return cumulativeMonthly.map(([key], index) => {
    const keyComponents = key.split('M');
    return {
      value1: cumulative
        ? cumulativeMonthly[index]?.[1] ?? 0
        : monthly[index]?.[1] ?? 0,
      value2: yearTotal,
      label: formatDate(
        new Date(
          parseInt(keyComponents[0].padStart(4, '20')),
          parseInt(keyComponents[1]) - 1,
          1
        ),
        { day: false, year: false }
      ),
    };
  });
};

const FiltersContainer = styled(Box)`
  border: 1px solid ${({ theme }) => theme.palette.divider};
  border-radius: 10px;
  padding: 0 32px;
`;

const RecommendationsBanner = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'changes',
})<{ changes: boolean }>(
  ({ theme, changes }) => `
  padding: 6px 12px;
  border: ${changes ? 'none' : `1px solid ${theme.palette.grey[100]}`} ;
  border-radius: 8px;
  background-color: ${changes ? theme.palette.primary.main : 'white'};
  cursor: pointer;

  &:hover {
    background-color: ${changes ? theme.palette.primary.light : '#f5f5f5'};
  }

  & * {
  fill: ${changes ? 'white' : theme.palette.primary.main};
  color: ${changes ? 'white' : theme.palette.primary.main};
}
`
);

export default BudgetDetails;
