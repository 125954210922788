import React, { useEffect, useState, createContext } from "react";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebaseConfig";
import Loading from "../components/Loading/Loading";

export const CaseStatusContext = createContext<{
  data: CaseStatus[];
}>({
  data: [],
});

interface CaseStatus {
  legal_cases: number;
  list_id: string;
  list_name: string;
  order: number;
}

type CasesStatusProviderProps = {
  children: React.ReactNode;
};

const CasesStatusProvider: React.FC<CasesStatusProviderProps> = ({
  children,
}) => {
  const [data, setData] = useState<CaseStatus[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const docRef = doc(
        db,
        "investis-legal-cases",
        "count_legal_cases_per_status"
      );
      const docSnap = await getDoc(docRef);
      const dataList: CaseStatus[] = [];

      if (docSnap.exists()) {
        dataList.push(docSnap.data() as CaseStatus);
      }

      setData(dataList);
      setIsLoading(false);
    };

    fetchData();
  }, []);

  return (
    <CaseStatusContext.Provider value={{ data }}>
      {isLoading ? <Loading /> : children}
    </CaseStatusContext.Provider>
  );
};

export default CasesStatusProvider;
