import React, {
  ChangeEventHandler,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { useTheme } from '@mui/material/styles';
import { ReactComponent as FiltersIcon } from '../../assets/icons/filters.svg';

import PageTitle from '../PageTitle/PageTitle';

import 'react-day-picker/dist/style.css';
import { useLocation, useNavigate, Location } from 'react-router-dom';
import {
  CustomLegend,
  CustomTooltip,
  ElectricityDot,
  HeatingDot,
  MazoutDot,
  WaterDot,
} from './CustomChartElements';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import Switch from '@mui/material/Switch';
import {
  columnsAggregated,
  columnsNotAggregated,
} from './DataGridColumnsHelper';

import cloudDownload from '../../assets/icons/cloudDownload.svg';
import exportCsv from '../../assets/icons/exportCsv.svg';
import exportXlsx from '../../assets/icons/exportXlsx.svg';

import { CSVLink } from 'react-csv';
import { format } from 'date-fns';

import * as XLSX from 'xlsx';
import { ConsumptionContext } from '../../context/ConsumptionContext';
import {
  filterAggregatedData,
  matchHeatingParamsToBuildingData,
} from './AggregateConsumption';
import UtilityCard from '../UtilityCard/UtilityCard';
import FilterPill from '../FilterPill/FilterPill';
import { doc, setDoc } from 'firebase/firestore';
import { db } from '../../firebaseConfig';
import { User } from 'firebase/auth';
import DataGrid from '../DataGrid/DataGrid';
import {
  styled,
  Box,
  Button,
  Typography,
  Menu,
  MenuItem,
  IconButton,
  Checkbox,
  FormControlLabel,
  Modal,
} from '@mui/material';
import { useAppSelector } from '../../store';
import { selectMode } from '../../store/ui.slice';

export interface Building {
  id: string;
  address: string;
  fullAddress: string;
  city: string;
  postcode: string;
  canton: string;
}

export interface ConsumptionItem {
  building_id: string;
  year: number;
  electricity_total: number;
  electricity_produced: number;
  gas_total: number;
  water_total: number;
  heating_total: number;
  mazout_total: number;
  building_address?: string;
  building_sre?: number;
  building_commune?: string;
  building_energy_agent?: string;
  electricity_m2?: number;
  gas_m2?: number;
  water_m2: number;
  heating_m2: number;
  building_city?: string;
  building_canton?: string;
}

export type ConsumptionAggregatedYearData = {
  year: number | string;
  electricity: number;
  gas: number;
  water: number;
  heating: number;
  mazout: number;
};

export interface BuildingHeatingItem {
  building_id: string;
  commune: string;
  energy_agent: string;
  sre: number;
}
export interface AggregatedData {
  [key: string]: ConsumptionAggregatedYearData;
}

interface CheckboxState {
  [key: string]: Array<string | number>;
}

interface ConsumptionProps {
  user: User;
  disclaimerConsent: boolean;
}

const Consumption: React.FC<ConsumptionProps> = ({
  user,
  disclaimerConsent,
}) => {
  const theme = useTheme();
  const location: Location = useLocation();
  const navigate = useNavigate();
  const mode = useAppSelector(selectMode);

  // CONTEXT HANDLERS
  const data = useContext(ConsumptionContext);

  const exportButtonRef = useRef<HTMLButtonElement>(null);

  const [buildingsList, setBuildingsList] = useState<Building[]>([]);
  const [filteredBuildings, setFilteredBuildings] = useState<Building[]>([]);

  const [consumptionData, setConsumptionData] = useState<ConsumptionItem[]>([]);
  const [aggregatedConsumption, setAggregatedConsumption] = useState<
    ConsumptionAggregatedYearData[]
  >([]);
  // const [agg, setAgg] = useState<ConsumptionAggregatedYearData[]>([]);

  const [utilitiesFilters, setUtilitiesFilters] = useState<string[]>([]);

  const [searchItem, setSearchItem] = useState('');
  const [checkedState, setCheckedState] = useState<CheckboxState>({});

  const handleCheckboxStateFromUrl = (location: Location) => {
    const params = new URLSearchParams(location.search);
    let checkboxObject: CheckboxState = {};

    params.forEach((value, key) => {
      if (checkboxObject[key]) {
        checkboxObject[key].push(value);
      } else {
        checkboxObject[key] = [value];
      }
    });
    setCheckedState(checkboxObject);

    if (params.size > 0) {
      setFilterPillsVisible(true);
    }
  };

  useEffect(() => {
    if (
      data &&
      data.data &&
      data.data.buildings &&
      data.data.consumption &&
      data.data.heating
    ) {
      setBuildingsList(data.data.buildings);
      setFilteredBuildings(data.data.buildings);
      const { filteredData, filteredAggregatedData } = filterAggregatedData(
        location,
        data.data.consumption,
        data.data.buildings
      );
      const filteredDataAddressHeating = matchHeatingParamsToBuildingData(
        filteredData,
        data.data.heating
      );
      setConsumptionData(filteredDataAddressHeating);
      setAggregatedConsumption(filteredAggregatedData);
      handleCheckboxStateFromUrl(location);
    }
  }, [data, location]);

  // VISIBILITY HANDLERS
  const [filtersVisible, setFiltersVisible] = useState<boolean>(false);
  const [disclaimerVisible, setDisclaimerVisible] = useState<boolean>(
    !disclaimerConsent
  );
  const [isExportMenuVisible, setIsExportMenuVisible] =
    useState<boolean>(false);
  const [aggregatedDataDisplay, setAggregatedDataDisplay] =
    useState<boolean>(true);
  const [filterPillsVisible, setFilterPillsVisible] = useState<boolean>(false);

  const handleVisibility = (variant: string) => {
    if (variant === 'filters') {
      setFiltersVisible(!filtersVisible);
    } else if (variant === 'disclaimer') {
      setDisclaimerVisible(false);
      const userRef = doc(db, `investis-amp-user-consent/${user.uid}`);
      setDoc(userRef, { acceptedConsumptionDisclaimer: true });
    } else if (variant === 'export') {
      setIsExportMenuVisible(!isExportMenuVisible);
    } else if (variant === 'aggregatedData') {
      setAggregatedDataDisplay(!aggregatedDataDisplay);
    }
  };

  const handleFilterInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const searchTerm = e.target.value;
    setSearchItem(searchTerm);

    const filteredItems = buildingsList.filter((building) => {
      return (
        building.fullAddress
          ?.toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        building.id.includes(searchTerm.toLowerCase())
      );
    });

    setFilteredBuildings(filteredItems);
  };

  const handleCheckboxChange = (
    id: string,
    filterType: string,
    isChecked: boolean
  ) => {
    setCheckedState((prevState) => {
      const newState = { ...prevState };
      if (!newState[filterType]) newState[filterType] = [];
      if (isChecked) {
        newState[filterType] = [...newState[filterType], id];
      } else {
        newState[filterType] = newState[filterType].filter(
          (filterId) => filterId !== id
        );
      }

      return newState;
    });
  };

  const handleFiltersSubmit = () => {
    const newSearchParams = new URLSearchParams();

    Object.entries(checkedState).forEach(([filterType, filterValues]) => {
      filterValues.forEach((filterValue) => {
        if (filterType !== 'start-year' && filterType !== 'end-year') {
          newSearchParams.append(filterType, filterValue.toString());
        } else {
          newSearchParams.set(
            filterType,
            new Date(filterValue).getFullYear().toString()
          );
        }
      });
    });

    handleVisibility('filters');
    setFilterPillsVisible(true);
    navigate({ search: newSearchParams.toString() });
  };

  const handleFiltersReset = () => {
    handleVisibility('filters');
    navigate('/consumption');
  };

  const handleUtilityCardClick = (value: string) => {
    let filters = utilitiesFilters;

    if (filters.includes(value.toString())) {
      filters = filters.filter((item) => item !== value.toString());
    } else {
      filters.push(value.toString());
    }

    setUtilitiesFilters(filters);
    const url = new URL(window.location.href);
    const newSearchParams = new URLSearchParams(url.search);

    newSearchParams.delete('utility');

    filters.forEach((item) => {
      newSearchParams.append('utility', item.toString());
    });

    navigate({ search: newSearchParams.toString() });
  };

  const extractHeaders = (
    list: ConsumptionAggregatedYearData[] | ConsumptionItem[]
  ): string[] => {
    return Array.from(new Set(list.flatMap(Object.keys)));
  };

  const exportToExcel = (data: any[], fileName: string) => {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, `${fileName}.xlsx`);
  };

  const handleColumnsChoice = () => {
    if (aggregatedDataDisplay) {
      return columnsAggregated;
    } else {
      if (utilitiesFilters.length === 0) {
        return columnsNotAggregated;
      } else {
        return columnsNotAggregated.filter((item) =>
          item.variants?.some((variant) => utilitiesFilters.includes(variant))
        );
      }
    }
  };

  const handleFilterPillClick = (target: string) => {
    if (filterPillsVisible) {
      const url = new URL(window.location.href);
      const params = new URLSearchParams(url.search);
      params.delete(target);
      navigate({ search: params.toString() });
    }
  };

  return (
    <Box display="flex" flexDirection="column" gap={3}>
      <Box
        width="100%"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <PageTitle title="Consumption" />
        <Box display="flex" alignItems="center" gap={4}>
          <Box display="flex" alignItems="center" gap={1}>
            <Switch disabled />
            <Typography variant="body2">Weather Conditions</Typography>
            <ComingSoonBadge display="flex" alignItems="center">
              <Typography variant="caption" color="white" fontSize={8}>
                Coming Soon
              </Typography>
            </ComingSoonBadge>
          </Box>
          <Box display="flex" alignItems="center" gap={1}>
            <FiltersButton
              variant="contained"
              size="small"
              startIcon={<FiltersIcon />}
              onClick={() => handleVisibility('filters')}
            >
              Filters
            </FiltersButton>
            <ExportButton
              variant="contained"
              size="small"
              startIcon={<img src={cloudDownload} alt="Download Icon" />}
              ref={exportButtonRef}
              onClick={() => handleVisibility('export')}
            >
              Export Current View
            </ExportButton>
            <ExportMenu
              open={isExportMenuVisible}
              anchorEl={exportButtonRef.current}
              disablePortal
              onClose={() => setIsExportMenuVisible(false)}
            >
              {aggregatedDataDisplay ? (
                <>
                  <CSVLink
                    data={aggregatedConsumption}
                    headers={extractHeaders(aggregatedConsumption)}
                    filename={`Aggregated Consumption Data ${format(
                      new Date(),
                      'yyyyMMddHHmmss'
                    )}.csv`}
                    style={{
                      textDecoration: 'none',
                      color: theme.palette.text.primary,
                    }}
                  >
                    <ExportMenuItem>
                      <img src={exportCsv} alt="Export view as CSV" />
                      <Typography variant="body2">
                        Export view as CSV
                      </Typography>
                    </ExportMenuItem>
                  </CSVLink>
                  <ExportMenuItem
                    onClick={() =>
                      exportToExcel(
                        aggregatedConsumption,
                        `Aggregated Consumption Data ${format(
                          new Date(),
                          'yyyyMMddHHmmss'
                        )}`
                      )
                    }
                  >
                    <img src={exportXlsx} alt="Export view as XLSX" />
                    <Typography variant="body2">Export view as XLSX</Typography>
                  </ExportMenuItem>
                </>
              ) : (
                <>
                  <CSVLink
                    data={consumptionData}
                    headers={extractHeaders(consumptionData)}
                    filename={`Consumption Data ${format(
                      new Date(),
                      'yyyyMMddHHmmss'
                    )}.csv`}
                    style={{
                      textDecoration: 'none',
                      color: theme.palette.text.primary,
                    }}
                  >
                    <ExportMenuItem>
                      <img src={exportCsv} alt="Export view as CSV" />
                      <Typography variant="body2">
                        Export view as CSV
                      </Typography>
                    </ExportMenuItem>
                  </CSVLink>
                  <ExportMenuItem
                    onClick={() =>
                      exportToExcel(
                        consumptionData,
                        `Consumption Data ${format(
                          new Date(),
                          'yyyyMMddHHmmss'
                        )}`
                      )
                    }
                  >
                    <img src={exportXlsx} alt="Export view as XLSX" />
                    <Typography variant="body2">Export view as XLSX</Typography>
                  </ExportMenuItem>
                </>
              )}
            </ExportMenu>
          </Box>
          {filtersVisible && (
            <Filters display="flex" flexDirection="column" gap={2} mode={mode}>
              <Box
                width="100%"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="h5">Filters</Typography>
                <CloseButton onClick={() => handleVisibility('filters')}>
                  ×
                </CloseButton>
              </Box>
              <FiltersWrapper
                width="100%"
                height="calc(100% - 96px)"
                display="flex"
                flexDirection="column"
                gap={2}
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                >
                  <Typography variant="subtitle1" fontWeight="bold">
                    Date Start
                  </Typography>
                  <DatePicker
                    views={['year']}
                    defaultValue={
                      checkedState['start-year']
                        ? new Date(checkedState['start-year'][0])
                        : new Date('2017')
                    }
                    slotProps={{
                      desktopPaper: {
                        sx: {
                          borderRadius: '10px',
                        },
                      },
                      layout: {
                        sx: {
                          backgroundColor: theme.palette.background.default,
                          borderRadius: '10px',
                          '& .MuiPickersYear-yearButton': {
                            borderRadius: '8px',
                            '&:hover': {
                              backgroundColor: theme.cardBackground,
                            },
                            '&.Mui-selected': {
                              backgroundColor: `${theme.palette.secondary.main} !important`,
                              '&:hover': {
                                backgroundColor: `${theme.palette.secondary.light} !important`,
                              },
                            },
                          },
                          '& .MuiDateCalendar-root': {
                            height: 'auto',
                          },
                        },
                      },
                    }}
                    onChange={(e) =>
                      handleCheckboxChange(
                        format(e as Date, 'y-MM-dd') ?? '2017-01-01',
                        'start-year',
                        true
                      )
                    }
                    minDate={new Date('2017-01-01')}
                    maxDate={
                      checkedState['end-year']
                        ? new Date(checkedState['end-year'][0])
                        : new Date()
                    }
                    sx={{
                      '& .MuiInputBase-root.MuiOutlinedInput-root': {
                        border: '1px solid #D0D5DD',
                        borderRadius: '8px',
                        fontFamily: 'unset',
                        fontSize: '14px',
                        lineHeight: 'unset',
                      },
                      '& .MuiInputBase-input.MuiOutlinedInput-input': {
                        padding: '8px 12px',
                        border: 'none',
                        fontSize: '14px',
                        height: 'unset',
                      },
                      '& .MuiInputAdornment-positionEnd': {},
                      '& .MuiButtonBase-root': {
                        color: theme.palette.text.secondary,
                      },
                      '& .MuiOutlinedInput-notchedOutline': {
                        background: theme.cardBackground,
                        border: 'none',
                        height: 'unset',
                      },
                    }}
                  />
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                >
                  <Typography variant="subtitle1" fontWeight="bold">
                    Date End
                  </Typography>
                  <DatePicker
                    views={['year']}
                    defaultValue={
                      checkedState['end-year']
                        ? new Date(checkedState['end-year'][0])
                        : new Date()
                    }
                    slotProps={{
                      desktopPaper: {
                        sx: {
                          borderRadius: '10px',
                        },
                      },
                      layout: {
                        sx: {
                          backgroundColor: theme.palette.background.default,
                          borderRadius: '10px',
                          '& .MuiPickersYear-yearButton': {
                            borderRadius: '8px',
                            '&:hover': {
                              backgroundColor: theme.cardBackground,
                            },
                            '&.Mui-selected': {
                              backgroundColor: `${theme.palette.secondary.main} !important`,
                              '&:hover': {
                                backgroundColor: `${theme.palette.secondary.light} !important`,
                              },
                            },
                          },
                          '& .MuiDateCalendar-root': {
                            height: 'auto',
                          },
                        },
                      },
                    }}
                    onChange={(e) =>
                      handleCheckboxChange(
                        format(e as Date, 'y-MM-dd') ?? '2017-01-01',
                        'end-year',
                        true
                      )
                    }
                    minDate={
                      checkedState['start-year']
                        ? new Date(checkedState['start-year'][0])
                        : new Date('2017')
                    }
                    maxDate={new Date()}
                    sx={{
                      '& .MuiInputBase-root.MuiOutlinedInput-root': {
                        border: '1px solid #D0D5DD',
                        borderRadius: '8px',
                        fontFamily: 'unset',
                        fontSize: '14px',
                        lineHeight: 'unset',
                      },
                      '& .MuiInputBase-input.MuiOutlinedInput-input': {
                        padding: '8px 12px',
                        border: 'none',
                        fontSize: '14px',
                        height: 'unset',
                      },
                      '& .MuiInputAdornment-positionEnd': {},
                      '& .MuiButtonBase-root': {
                        color: theme.palette.text.secondary,
                      },
                      '& .MuiOutlinedInput-notchedOutline': {
                        background: theme.cardBackground,
                        border: 'none',
                        height: 'unset',
                      },
                    }}
                  />
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  gap={2}
                >
                  <Box
                    width="100%"
                    display="flex"
                    flexDirection="column"
                    gap={0.5}
                  >
                    <Typography variant="subtitle1" fontWeight="bold">
                      Building
                    </Typography>
                    <BuildingInput
                      className="BuildingSearch"
                      type="text"
                      value={searchItem}
                      onChange={handleFilterInputChange}
                      placeholder="Search a building"
                    />
                    <Typography variant="caption">
                      Search building address, ID, postcode, city
                    </Typography>
                  </Box>
                  <FilterGroup display="flex" flexDirection="column" gap={1}>
                    <Typography variant="subtitle1">Building Name</Typography>
                    {filteredBuildings.length === 0 ? (
                      <p>No buildings matching the search term</p>
                    ) : (
                      <Box display="flex" flexDirection="column" gap={0.5}>
                        {filteredBuildings.map((building) => (
                          <FormControlLabel
                            key={building.id}
                            label={building.address}
                            control={
                              <Checkbox
                                key={building.id}
                                checked={
                                  !!checkedState['building']?.includes(
                                    building.id
                                  )
                                }
                                onChange={(e) =>
                                  handleCheckboxChange(
                                    building.id,
                                    'building',
                                    e.target.checked
                                  )
                                }
                                color="secondary"
                              />
                            }
                          ></FormControlLabel>
                        ))}
                      </Box>
                    )}
                  </FilterGroup>
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                >
                  <Typography variant="subtitle1" fontWeight="bold">
                    City
                  </Typography>
                  <FilterGroup display="flex" flexDirection="column" gap={1}>
                    {['Genève'].length === 0 ? (
                      <p>No cities matching the search term</p>
                    ) : (
                      <Box display="flex" flexDirection="column" gap={0.5}>
                        {['Genève', 'Meyrin'].map((city) => (
                          <FormControlLabel
                            key={city}
                            label={city}
                            control={
                              <Checkbox
                                key={city}
                                checked={!!checkedState['city']?.includes(city)}
                                onChange={(e) =>
                                  handleCheckboxChange(
                                    city,
                                    'city',
                                    e.target.checked
                                  )
                                }
                                color="secondary"
                              />
                            }
                          ></FormControlLabel>
                        ))}
                      </Box>
                    )}
                  </FilterGroup>
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                >
                  <Typography variant="subtitle1" fontWeight="bold">
                    Canton
                  </Typography>
                  <FilterGroup display="flex" flexDirection="column" gap={1}>
                    {['Canton de Genève'].length === 0 ? (
                      <p>No cantons matching the search term</p>
                    ) : (
                      <Box display="flex" flexDirection="column" gap={0.5}>
                        {['Canton de Genève'].map((city) => (
                          <FormControlLabel
                            key={city}
                            label={city}
                            control={
                              <Checkbox
                                key={city}
                                checked={!!checkedState['city']?.includes(city)}
                                onChange={(e) =>
                                  handleCheckboxChange(
                                    city,
                                    'city',
                                    e.target.checked
                                  )
                                }
                                color="secondary"
                              />
                            }
                          ></FormControlLabel>
                        ))}
                      </Box>
                    )}
                  </FilterGroup>
                </Box>
              </FiltersWrapper>
              <FilterButtons
                width="100%"
                display="flex"
                justifyContent="center"
                gap={1}
              >
                <ApplyButton
                  variant="contained"
                  size="small"
                  color="secondary"
                  onClick={handleFiltersSubmit}
                >
                  Apply
                </ApplyButton>
                <ResetButton
                  variant="outlined"
                  size="small"
                  onClick={handleFiltersReset}
                >
                  Reset
                </ResetButton>
              </FilterButtons>
            </Filters>
          )}
        </Box>
      </Box>
      <Box display="flex" alignItems="center" gap={2}>
        <FilterPill
          title="# of Buildings Chosen"
          value={
            checkedState.building?.length && filterPillsVisible
              ? checkedState.building.length
              : buildingsList.length
          }
          onClickCustom={() => handleFilterPillClick('building')}
          displayX={
            checkedState.building?.length && filterPillsVisible ? true : false
          }
        />

        {checkedState.city?.length && filterPillsVisible ? (
          <FilterPill
            title="# of Cities Chosen"
            value={checkedState.city?.length ?? 0}
            onClickCustom={() => handleFilterPillClick('city')}
            displayX={true}
          />
        ) : null}
        {checkedState.canton?.length && filterPillsVisible ? (
          <FilterPill
            title="# of Cantons Chosen"
            value={checkedState.canton?.length ?? 0}
            onClickCustom={() => handleFilterPillClick('canton')}
            displayX={true}
          />
        ) : null}
        {checkedState['start-year']?.length && filterPillsVisible ? (
          <FilterPill
            title="Start Year"
            value={
              new Date(checkedState['start-year'][0]).getFullYear() ?? 2017
            }
            onClickCustom={() => handleFilterPillClick('start-year')}
            displayX={true}
          />
        ) : null}
        {checkedState['end-year']?.length && filterPillsVisible ? (
          <FilterPill
            title="End Year"
            value={new Date(checkedState['end-year'][0]).getFullYear() ?? 2024}
            onClickCustom={() => handleFilterPillClick('end-year')}
            displayX={true}
          />
        ) : null}
      </Box>
      <ChartCard display="flex" flexDirection="column" gap={2}>
        <Box display="flex" alignItems="center" gap={2}>
          <Typography variant="subtitle2" fontWeight="bold">
            Utilities Overview
          </Typography>
          <WeatherAdjustmentBadge display="flex" alignItems="center">
            <Typography variant="caption">Not adjusted for weather</Typography>
          </WeatherAdjustmentBadge>
        </Box>
        <ResponsiveContainer width="100%" height={300}>
          <LineChart
            data={aggregatedConsumption}
            margin={{ top: 20, right: 40, left: 30, bottom: -10 }}
          >
            <XAxis
              dataKey="year"
              stroke={theme.palette.text.disabled}
              axisLine={false}
              tickLine={false}
              tickMargin={10}
            />
            <YAxis
              label={{
                offset: -10,
                value: 'RELATIVE CHANGE [%]',
                angle: -90,
                position: 'insideBottomLeft',
                fill: theme.palette.text.disabled,
              }}
              tickMargin={20}
              stroke={theme.palette.text.disabled}
              axisLine={false}
              tickLine={false}
            />
            <Tooltip content={<CustomTooltip />} />
            <Legend content={<CustomLegend />} />
            {utilitiesFilters.length === 0 ||
            utilitiesFilters.includes('electricity') ? (
              <Line
                type="monotone"
                dataKey="electricity"
                stroke="#FFD029"
                strokeWidth={2}
                dot={<ElectricityDot />}
                activeDot={{ r: 8 }}
              />
            ) : null}
            {utilitiesFilters.length === 0 ||
            utilitiesFilters.includes('gas') ? (
              <Line
                type="monotone"
                dataKey="gas"
                stroke="#3DC092"
                strokeWidth={2}
                dot={{ fill: '#3DC092' }}
                activeDot={{ r: 8 }}
              />
            ) : null}
            {utilitiesFilters.length === 0 ||
            utilitiesFilters.includes('water') ? (
              <Line
                type="monotone"
                dataKey="water"
                stroke="#429AF2"
                strokeWidth={2}
                dot={<WaterDot />}
                activeDot={{ r: 8 }}
              />
            ) : null}
            {utilitiesFilters.length === 0 ||
            utilitiesFilters.includes('heating') ? (
              <Line
                type="monotone"
                dataKey="heating"
                stroke="#FF837B"
                strokeWidth={2}
                dot={<HeatingDot />}
                activeDot={{ r: 8 }}
              />
            ) : null}
            {utilitiesFilters.length === 0 ||
            utilitiesFilters.includes('mazout') ? (
              <Line
                type="monotone"
                dataKey="mazout"
                stroke="#606976"
                strokeWidth={2}
                connectNulls={false}
                dot={(dotProps) => (
                  <MazoutDot {...dotProps} index={dotProps.index} />
                )}
                activeDot={{ r: 8 }}
              />
            ) : null}
          </LineChart>
        </ResponsiveContainer>
      </ChartCard>
      <Box display="grid" gridTemplateColumns="repeat(5, 1fr)" gap={3}>
        <UtilityCard
          title="Electricity"
          value={11.0}
          active={utilitiesFilters.includes('electricity')}
          onClick={() => handleUtilityCardClick('electricity')}
          unit="%"
          source="SIG"
        />
        <UtilityCard
          title="Gas"
          // value={compareConsumption('gas') as number}
          value={22.12}
          active={utilitiesFilters.includes('gas')}
          onClick={() => handleUtilityCardClick('gas')}
          unit="%"
          source="SIG"
        />
        <UtilityCard
          title="Water"
          value={-8.7}
          active={utilitiesFilters.includes('water')}
          onClick={() => handleUtilityCardClick('water')}
          unit="%"
          source="SIG"
        />
        <UtilityCard
          title="Heating"
          // value={compareConsumption('heating') as number}
          value={3.22}
          active={utilitiesFilters.includes('heating')}
          onClick={() => handleUtilityCardClick('heating')}
          unit="%"
          source="SIG"
        />
        <UtilityCard
          title="Mazout"
          // value={compareConsumption('mazout') as number}
          value={-18.23}
          active={utilitiesFilters.includes('mazout')}
          onClick={() => handleUtilityCardClick('mazout')}
          unit="%"
          source="SilentSoft; Chauffage Assistance"
        />
      </Box>
      <Modal
        open={disclaimerVisible}
        onClose={() => handleVisibility('disclaimer')}
        sx={{
          '*': { outline: 'none' },
          '.MuiBackdrop-root': { backgroundColor: 'transparent' },
        }}
      >
        <DisclaimerContainer position="absolute" top="20%" left="50%">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h5" fontWeight="bold">
              Disclaimer
            </Typography>
            <CloseButton onClick={() => handleVisibility('disclaimer')}>
              ×
            </CloseButton>
          </Box>
          <Typography variant="body2">
            The data displayed on this page, provided by Services Industriels de
            Genève (SIG) and processed by Perty, represents{' '}
            <b>average values</b> and may not reflect actual consumption levels.
            <br />
            For precise details on your real consumption, we warmly invite you
            to contact us at{' '}
            <StyledLink href="mailto:contact@perty.immo">
              contact@perty.immo
            </StyledLink>
          </Typography>
        </DisclaimerContainer>
      </Modal>
      <Box display="flex" flexDirection="column" gap={1}>
        <Box display="flex" alignItems="center" gap={2}>
          <Typography variant="subtitle1" fontWeight="bold">
            Details
          </Typography>
          <Box display="flex" alignItems="center" gap={0.5}>
            <Switch
              defaultChecked
              onChange={() => handleVisibility('aggregatedData')}
            />
            <Typography variant="subtitle2">Display Aggregated Data</Typography>
          </Box>
        </Box>
        <DataGrid
          height="400px"
          rows={(aggregatedDataDisplay
            ? aggregatedConsumption
            : consumptionData
          ).map((item) => ({
            ...item,
            id: item.year,
          }))}
          columns={handleColumnsChoice()}
          getRowId={(row) =>
            aggregatedDataDisplay ? row.year : row.year + row.building_id
          }
          hideFooter={true}
        />
      </Box>
    </Box>
  );
};

const ChartCard = styled(Box)`
  padding: 16px;
  border-radius: 12px;
  background: ${({ theme }) => theme.cardBackground};
  box-shadow: 3px 3px 10px 1px rgba(16, 24, 40, 0.05);
`;

const ExportButton = styled(Button)(
  ({ theme }) => `
    padding: 6px 12px;
    border-radius: 8px;
    background-color: ${theme.palette.secondary.main};
    box-shadow: 3px 3px 10px 1px rgba(16, 24, 40, 0.05);
    text-transform: none;
    
    &:hover {
    background-color: ${theme.palette.secondary.light};
    box-shadow: 3px 3px 10px 1px rgba(16, 24, 40, 0.05);
    `
);

const ComingSoonBadge = styled(Box)`
  align-self: start;
  padding: 0 6px;
  margin-left: -6px;
  background: ${({ theme }) => theme.palette.primary.light};
  border-radius: 10px;
  box-shadow: 3px 3px 10px 1px rgba(16, 24, 40, 0.05);
`;

const DisclaimerContainer = styled(Box)`
  transform: translate(-50%, 0);
  padding: 24px;
  background: ${({ theme }) => theme.cardBackground};
  border-radius: 12px;
  backdrop-filter: blur(10px);
  box-shadow: 3px 3px 10px 1px rgba(16, 24, 40, 0.05);
`;

const StyledLink = styled('a')`
  color: ${({ theme }) => theme.palette.text.primary};
  font-weight: bold;
`;

const FiltersButton = styled(Button)(
  ({ theme }) => `
  padding: 6px 12px;
  border-radius: 8px;
  background-color: ${theme.cardBackground};
  border: 1px solid rgba(255, 255, 255, 0.3);
  color: ${theme.palette.text.secondary};
  box-shadow: 3px 3px 10px 1px rgba(16, 24, 40, 0.05);
  text-transform: none;

  &:hover {
    background-color: ${theme.palette.divider};
    box-shadow: 3px 3px 10px 1px rgba(16, 24, 40, 0.05);
      border: 1px solid rgba(255, 255, 255, 0.6);

`
);

const WeatherAdjustmentBadge = styled(Box)`
  padding: 0 6px;
  margin-left: -6px;
  background: ${({ theme }) => theme.cardBackground};
  border-radius: 10px;
  box-shadow: 3px 3px 10px 1px rgba(16, 24, 40, 0.05);
`;

const Filters = styled(Box, { shouldForwardProp: (prop) => prop !== 'mode' })<{
  mode: 'light' | 'dark';
}>(
  ({ theme, mode }) => `
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  overflow-y: scroll;
  width: 25rem;
  height: 100vh;
  background: linear-gradient(
    140deg,
    rgba(255, 255, 255, ${mode === 'light' ? 0.7 : 0.3}) 40%,
    ${theme.palette.primary.main} 200%
  );
  backdrop-filter: blur(20px);
  z-index: 9;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-left: 1px solid ${theme.palette.divider};
  box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03),
    0px 20px 24px -4px rgba(16, 24, 40, 0.08);
  padding: 24px;
  box-sizing: border-box;
`
);

const ApplyButton = styled(Button)(
  ({ theme }) => `
  padding: 6px 12px;
    border-radius: 8px;
    background-color: ${theme.palette.secondary.main};
    box-shadow: 3px 3px 10px 1px rgba(16, 24, 40, 0.05);
    text-transform: none;
    
    &:hover {
    background-color: ${theme.palette.secondary.light};
    box-shadow: 3px 3px 10px 1px rgba(16, 24, 40, 0.05);
    }
`
);

const ResetButton = styled(Button)(
  ({ theme }) => `
  padding: 6px 12px;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.4);
  border: 1px solid rgba(255, 255, 255, 0.3);
  color: ${theme.palette.text.secondary};
  box-shadow: 3px 3px 10px 1px rgba(16, 24, 40, 0.05);
  text-transform: none;
  
  &:hover {
    background-color: rgba(255, 255, 255, 0.7);
    box-shadow: 3px 3px 10px 1px rgba(16, 24, 40, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.3);
  }
`
);

const BuildingInput = styled('input')`
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  padding: 10px 14px;
  outline: none;
  color: ${({ theme }) => theme.palette.text.primary};
  &::placeholder {
    color: ${({ theme }) => theme.palette.text.secondary};
  }
`;

const ExportMenu = styled(Menu)`
  & .MuiPaper-root {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 4px;
    border-radius: 8px;
    background-color: ${({ theme }) => theme.cardBackground};
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }
`;

const ExportMenuItem = styled(MenuItem)`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
`;

const CloseButton = styled(IconButton)`
  height: 32px;
  width: 32px;
  color: ${({ theme }) => theme.palette.text.primary};
`;

const FiltersWrapper = styled(Box)`
  overflow-y: auto;
  scrollbar-width: none;
`;

const FilterGroup = styled(Box)`
  padding: 8px 16px;
  background: ${({ theme }) => theme.cardBackground};
  border-radius: 8px;
`;

const FilterButtons = styled(Box)`
  padding: 0 24px;

  & * {
    flex-grow: 1;
  }
`;
export default Consumption;
