import React, { useEffect, useState, createContext } from "react";
import { collection, onSnapshot, Timestamp } from "firebase/firestore";
import { db } from "../firebaseConfig";

type PortfolioHealthWeeklyValues = {
  max_date: Timestamp;
  min_date: Timestamp;
  value: number;
};
type PortfolioHealthDailyValues = {
  date: string;
  value: number;
};

type PortfolioHealthData = {
  weekly: {
    [week: string]: PortfolioHealthWeeklyValues;
  };
  daily: {
    [day: string]: PortfolioHealthDailyValues;
  };
  current: number;
};

const initialValue: PortfolioHealthData = {
  weekly: {},
  daily: {},
  current: 0,
};

export const PortfolioHealthContext =
  createContext<PortfolioHealthData>(initialValue);

type PortfolioHealthProviderProps = {
  children: React.ReactNode;
};

const PortfolioHealthProvider: React.FC<PortfolioHealthProviderProps> = ({
  children,
}) => {
  const [data, setData] = useState<PortfolioHealthData>(initialValue);

  useEffect(() => {
    const dailyRef = collection(db, "investis-portfolio-health-daily");
    const weeklyRef = collection(db, "investis-portfolio-health-weekly");

    onSnapshot(dailyRef, async (snapshot) => {
      const dailyData = Object.fromEntries(
        snapshot.docs.map((doc) => [
          doc.id,
          doc.data() as PortfolioHealthDailyValues,
        ])
      );
      const current = Object.values(dailyData).at(-1)?.value ?? 0;
      setData((prev) => ({ ...prev, daily: dailyData, current }));
    });

    onSnapshot(weeklyRef, async (snapshot) => {
      const weeklyData = Object.fromEntries(
        snapshot.docs.map((doc) => [
          doc.id,
          doc.data() as PortfolioHealthWeeklyValues,
        ])
      );
      setData((prev) => ({ ...prev, weekly: weeklyData }));
    });
  }, []);

  return (
    <PortfolioHealthContext.Provider value={data}>
      {children}
    </PortfolioHealthContext.Provider>
  );
};

export default PortfolioHealthProvider;
