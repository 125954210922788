import { collection, getDocs } from "firebase/firestore";
import React, { createContext, useEffect, useState } from "react";
import { db } from "../firebaseConfig";
import Loading from "../components/Loading/Loading";

interface OccupancyDataItem {
  days: number;
  month: number;
  occupancy: number;
  unit_days: number;
  unit_days_vacant: number;
  year: number;
  units_vacant_count: number;
}

type OccupancyData = {
  monthly: OccupancyDataItem[];
  currentPerc: number;
  currentUnitsVacant: number;
};

const initialValue: OccupancyData = {
  monthly: [],
  currentPerc: 0,
  currentUnitsVacant: 0,
};

export const OccupancyContext = createContext<OccupancyData>(initialValue);

type OccupancyProviderProps = {
  children: React.ReactNode;
};

const OccupancyProvider: React.FC<OccupancyProviderProps> = ({ children }) => {
  const [data, setData] = useState(initialValue);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const collectionRef = collection(db, "investis-occupancy");
      const snapshot = await getDocs(collectionRef);

      if (!snapshot.empty) {
        const docs = snapshot.docs.map(
          (doc) => doc.data() as OccupancyDataItem
        );

        const currentPerc = docs.at(-1)?.occupancy;
        const currentUnitsVacant = docs.at(-1)?.units_vacant_count;

        setData({
          monthly: docs,
          currentPerc: currentPerc ?? 0,
          currentUnitsVacant: currentUnitsVacant ?? 0,
        });
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <OccupancyContext.Provider value={data}>
      {isLoading ? <Loading /> : children}
    </OccupancyContext.Provider>
  );
};

export default OccupancyProvider;
