import { Navigate } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
import Sidebar from "../Sidebar/Sidebar";
import { Box, styled } from "@mui/material";
import { useContext, useEffect } from "react";
import { AuthContext } from "../../context/AuthContext";
import { useAppDispatch, useAppSelector } from "../../store";
import { selectFade, setFade } from "../../store/ui.slice";

interface PrivateRouteProps {
  children: React.ReactNode;
  showLayout?: boolean;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  children,
  showLayout = true,
}) => {
  const dispatch = useAppDispatch();
  const { isAuthenticated } = useContext(AuthContext);
  const fade = useAppSelector(selectFade);

  useEffect(() => {
    if (fade) {
      dispatch(setFade(false));
    }
  }, [dispatch, fade]);

  if (isAuthenticated === false) {
    return <Navigate to="/" />;
  }
  return (
    <>
      {showLayout ? (
        <Fade display="flex" width="100vw" height="100vh" enabled={fade}>
          <Sidebar />
          <ContentContainer width="100vw" height="100vh">
            <Content
              width="100%"
              height="100%"
              display="flex"
              flexDirection="column"
            >
              <Navbar />
              {children}
            </Content>
          </ContentContainer>
        </Fade>
      ) : (
        children
      )}
    </>
  );
};

const Fade = styled(Box)<{ enabled: boolean }>`
  opacity: ${(props) => (props.enabled ? 0 : 1)};
  transition: all 1s ease;
`;

const ContentContainer = styled(Box)`
  border-radius: 32px 0 0 32px;
  overflow-x: hidden;
`;

const Content = styled(Box)`
  padding: ${(props) => props.theme.spacing(4)};
  background: transparent;
  overflow-x: hidden;
  overflow-y: auto;
`;

export default PrivateRoute;
